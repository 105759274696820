<template>
    <div class="room-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    let validID = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持大小写字母，数字，限制1~32位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("广告ID格式不对"));
            } else {
                callback();
            }
        }
    };
    let validAdName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入中英文字母，汉字，1~64个字符
            let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,64}$/;
            if (!reg.test(value)) {
                callback(new Error("广告名称格式不对"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                dialogVisible: false,
                opt: {
                    title: "广告管理",
                    search: [{
                            key: "id",
                            label: "广告ID",
                            maxlength: 32,
                            rules: [{
                                validator: validID,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "location",
                            label: "广告位",
                            type: 'select-no-filterable',
                            opt: {
                                list: []
                            }
                        },
                        {
                            key: "adName",
                            label: "广告名称",
                            maxlength: 64,
                            rules: [{
                                validator: validAdName,
                                trigger: ['blur']
                            }]
                        },

                        {
                            key: "state",
                            label: "状态",
                            type: 'select-no-filterable',
                            opt: {
                                list: [{
                                        label: '全部',
                                        value: ''
                                    },
                                    {
                                        label: '上架',
                                        value: 1
                                    },
                                    {
                                        label: '下架',
                                        value: 0
                                    },
                                ]
                            }
                        },
                    ],
                    columns: [{
                            label: "ID",
                            key: "id"
                        },
                        {
                            label: "广告位",
                            key: "location"
                        },
                        {
                            label: "广告名称",
                            key: "adName"
                        },
                        {
                            label: "应用范围",
                            key: "adRange"
                        },
                        {
                            label: "排序",
                            key: "sort"
                        },
                        {
                            label: "备注",
                            key: "remark"
                        },
                        {
                            label: "状态",
                            key: "stateStr"
                        },
                        {
                            label: "广告图",
                            type: "img",
                            key: "picUrl"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-back",
                            opt: {
                                list: [{
                                        label: "查看",
                                        on(row) {
                                            _this.$router.push("/main/adManagement/detail/show/" + row.id);
                                        }
                                    },
                                    {
                                        label: "下架",
                                        on(row, cb) {
                                            _this.dialogVisible = true;
                                            const h = _this.$createElement;
                                            _this
                                                .$msgbox({
                                                    title: "确定提示",
                                                    message: h("p", null, [
                                                        h(
                                                            "span", {
                                                                style: "color: #333;font-size: 16px;margin-left:98px"
                                                            },
                                                            "确定要下架该广告吗？"
                                                        )
                                                    ]),
                                                    showCancelButton: true,
                                                    confirmButtonText: "确定",
                                                    cancelButtonText: "取消"
                                                })
                                                .then(action => {
                                                    var dto = {
                                                        id: row.id,
                                                        state: 0
                                                    };
                                                    _this.post("/landlord-service/advertiseInfo/update",
                                                            dto, {
                                                                isUseResponse: true,
                                                                isNotShowError: true,
                                                                operatorFlage: true,
                                                                operateName: "下架了广告",
                                                            })
                                                        .then(res => {
                                                            if (res.data.code == 0) {
                                                                //查看 下架 上架
                                                                row.action = [true, false, true];
                                                                row.stateStr = "下架";
                                                                row.state = 0;
                                                                cb({
                                                                    data: row,
                                                                    msg: "下架广告成功!",
                                                                    result: true,
                                                                });
                                                            }
                                                        })
                                                        .catch(res => {
                                                            cb({
                                                                result: false,
                                                                msg: res.data.msg
                                                            });
                                                        });
                                                });

                                        }
                                    },
                                    {
                                        label: "上架",
                                        on(row, cb) {
                                            _this.dialogVisible = true;
                                            const h = _this.$createElement;
                                            _this
                                                .$msgbox({
                                                    title: "确定提示",
                                                    message: h("p", null, [
                                                        h(
                                                            "span", {
                                                                style: "color: #333;font-size: 16px;margin-left:98px"
                                                            },
                                                            "确定要上架该广告吗？"
                                                        )
                                                    ]),
                                                    showCancelButton: true,
                                                    confirmButtonText: "确定",
                                                    cancelButtonText: "取消"
                                                })
                                                .then(action => {
                                                    var dto = {
                                                        id: row.id,
                                                        state: 1
                                                    };
                                                    // _this
                                                    //     .post("landlord-service/advertiseInfo/update", dto)
                                                    //     .then(function () {
                                                    //         _this.$message({
                                                    //             showClose: true,
                                                    //             message: "上架广告成功！",
                                                    //             type: "success"
                                                    //         });
                                                    //         _this.$refs.myList.get();
                                                    //     });
                                                    _this.post("landlord-service/advertiseInfo/update",
                                                            dto, {
                                                                isUseResponse: true,
                                                                isNotShowError: true,
                                                                operatorFlage: true,
                                                                operateName: "上架了广告",
                                                            })
                                                        .then(res => {
                                                            if (res.data.code == 0) {
                                                                //查看 下架 上架
                                                                row.action = [true, true, false];
                                                                row.stateStr = "上架";
                                                                row.state = 1;
                                                                cb({
                                                                    data: row,
                                                                    msg: "上架广告成功！",
                                                                    result: true,
                                                                });
                                                            }
                                                        })
                                                        .catch(res => {
                                                            cb({
                                                                result: false,
                                                                msg: res.data.msg
                                                            });
                                                        });
                                                });
                                        }
                                    },
                                ]
                            }
                        }
                    ],
                    buttons: [{
                            type: 0,
                            on() {
                                _this.$router.push("/main/adManagement/detail/add/0");
                            }
                        },
                        {
                            type: 1,
                            on(data) {
                                _this.$router.push("/main/adManagement/detail/edit/" + data.id);
                            }
                        },
                        {
                            type: 2,
                            on(data) {
                                // if (data.ids.length > 1) {
                                //     return _this.$message({
                                //         showClose: true,
                                //         message: "不支持多个删除,只支持单个删除!",
                                //         type: "warning"
                                //     });
                                // }
                                const h = _this.$createElement;
                                _this.$msgbox({
                                    title: "删除提示",
                                    message: h("p", null, [
                                        h(
                                            "span", {
                                                style: "color: #333;font-size: 16px;margin-left:98px"
                                            },
                                            "确定要删除广告吗？"
                                        )
                                    ]),
                                    showCancelButton: true,
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消"
                                }).then(action => {
                                    _this.post("landlord-service/advertiseInfo/deleteByIds", data.ids,{
                                        operatorFlage: true,
                                        operateName: '删除广告',
                                    }).then(
                                        function () {
                                            _this.$message({
                                                showClose: true,
                                                message: "删除广告成功！",
                                                type: "success"
                                            });
                                            data.refresh();
                                        });
                                });
                            }
                        }
                    ]
                },
            };
        },
        created() {},
        activated() {
            this.getAdvertisingSpace();
            this.getMenuName(this.$route.path)
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    id: opt.searchForm.id,
                    adName: opt.searchForm.adName,
                    state: (opt.searchForm.state === 0 || opt.searchForm.state == 1) ? opt.searchForm.state : null,
                };
                if (opt.searchForm.location) {
                    dto.location = opt.searchForm.location
                }
                this.post("/landlord-service/advertiseInfo/page/query", dto, {
                    isUseResponse: true
                }).then(res => {

                    res.data.data = res.data.list;
                    res.data.data.forEach(item => {
                        //查看 下架 上架
                        item.action = [true, false, false];
                        item.stateStr = ["下架", "上架"][item.state];
                        if (item.state == 1) {
                            item.action[1] = true;
                        } else if (item.state == 0) {
                            item.action[2] = true;
                        }
                    })
                    opt.cb(res.data);
                });
            },
            //获取广告位
            getAdvertisingSpace() {
                return new Promise((resolve, reject) => {
                    this.post("landlord-service/advertiseSpace/queryAdSpace", {}).then(res => {
                        res.forEach(item => {
                            item.value = item.spaceCode;
                            item.label = item.location;
                        })
                        res.unshift({
                            value: '',
                            label: '全部',
                        })
                        this.opt.search.forEach(item => {
                            if (item.key == "location") {
                                item.opt.list = res;
                            }
                        })
                        resolve(res)
                    })
                })
            },
        }
    };
</script>
<style lang="scss">
    .room-list {
        .content-top {
            width: 100%;
            height: 36px;
            line-height: 36px;
            background: rgba(242, 242, 242, 1);
            color: #666666;
            margin-bottom: 20px;
            padding-left: 10px;
            box-sizing: border-box;

            span {
                padding-left: 10px;
                padding-right: 10px;
            }

            label {
                color: #ff0000;
            }
        }

        .content-box {
            height: 620px;

            .form-box {
                height: 530px;
                overflow-y: auto;
                padding-right: 20px;

                .list-box {
                    margin: 0 auto;
                    max-width: 1200px;

                    .list-item {
                        padding: 4px 0;
                        margin-bottom: 6px;
                        background-color: #eee;

                        .list-idx {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            font-size: 14px;
                            color: #606266;
                        }

                        .list-col {
                            display: inline-block;

                            label {
                                margin-left: 10px;
                                margin-right: 6px;
                                font-size: 14px;
                                color: #606266;
                                line-height: 40px;
                            }

                            .el-input {
                                width: 120px;
                            }
                        }

                        .list-buts {
                            float: right;
                            padding-right: 10px;
                        }
                    }
                }

                .dashed-box {
                    width: 100%;
                    border-top: 1px dashed #cccccc;
                    margin-top: -12px;
                    margin-bottom: 12px;
                }
            }

            .btn-box {
                padding-left: 20px;
                margin-bottom: 20px;
            }

            .description {
                color: #999999;
            }
        }

        .bottom-box {
            width: 100%;
            text-align: center;
        }

        .el-dialog {
            margin-top: 8vh !important;
        }

        .el-time-panel {
            display: none !important;
        }
    }
</style>